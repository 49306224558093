<template>
  <div class="book"
      v-loading='data_running'>
    <content-title
      :title="$t('message.novels')"
      :min_title="'> ' + book_item.book_name"
      @back="back"
    ></content-title>
    <div class="book_box">
      <div class="book_left" style="width: 50.8%">
        <div class="img_box">
          <img :src="book_item.book_pic" alt="" />
          <span>{{
            book_item.update_status == 1
              ? $t("message.ended")
              : $t("message.running")
          }}</span>
        </div>
        <div class="synopsis flex-column flexCenter-jc ">
          <div class="name">{{ book_item.book_name }}</div>
          <div class="section flexCenter-ai-center">
            <span style="margin-right: 10px" v-if="book_item.writer_name != '' ">{{ book_item.writer_name }}</span>
            <span>{{$t('message.chapter')}} {{ book_item.section_num }}</span>
            <span style="margin-left: 10px">{{ book_item.word_num }}W{{$t('message.words')}}</span>
          </div>
          <div class="text">{{ book_item.book_desc }}</div>
        </div>
      </div>
      <div class="create_book_btn button-me" @click="createLinkDialog(book_item)">
        {{$t('message.promote')}}
      </div>
    </div>

    <!-- 章节列表 -->
    <div class="section_list_box" v-if="sectionArr.length > 0">
      <div class="section_title flexCenter-ai-center ">
        <span>{{$t('message.chapter')}}</span>
        <span>{{$t('message.price')}}</span>
      </div>
      <div class="section_list">
        <div class="section_item flexCenter-ai-center" v-for="(item, index) in sectionArr" :key="index">
          <span>{{ item.list_order }} {{ item.title }}（{{ item.word_num }}{{$t('message.words')}}）</span>
          <span>{{ item.is_charge === 0 ? $t('message.free') : $t('message.price')}}</span>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="sizeChange"
          :hide-on-single-page="true"
          :total="count"
          :page-size="data.limit"
        >
        </el-pagination>
      </div>
    </div>
    <div class="empty flexCenter flex-column" v-if="is_books">
      <img src="@/assets/images/empty.png" alt="" />
      <span>{{ $t("message.noMoreData") }}</span>
    </div>


    <!-- 创建链接 -->
    <el-dialog :visible.sync="book_channel_dialog" width="589px">
      <div class="title">{{ $t("message.createAReferralLink") }}</div>
      <div class="channel flex-align-items-flex-start">
        <span class="min_title">{{ $t("message.referralLink") }}: </span>
        <span class="val">{{create_item.book_name}}</span>
      </div>
      <div class="name">
        <span class="min_title">{{ $t("message.name") }}：</span>
        <el-input
          v-model="create_item.title"
          spellcheck="false"
          @keyup.enter.native="setBookList"
        ></el-input>
      </div>
      <div class="btn button-me" @click="createLink">
        {{ $t("message.create") }}
      </div>
    </el-dialog>
    <!-- 创建链接成功 -->
    <el-dialog :visible.sync="channel_dialog" width="589px">
      <div class="title">{{ $t("message.linkCreated") }}</div>
      <div class="link">
        <div class="hint">{{ $t("message.CopyLink") }}</div>
        <!-- <input type="text" class="input-me" /> -->
        <el-input
          placeholder="请输入内容"
          v-model="link"
          :disabled="true">
        </el-input>
      </div>
      <div class="copy_btn btn button-me" @click="copy(link)">
        {{ $t("message.copy") }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
import contentTitle from "@/components/contentTitle/contentTitle"; //顶部栏
import { bookDetail, sectionList, generateLink } from "@/apis/api";
export default {
  data() {
    return {
      count: 100,
      data: {
        page: 1,
        limit: 20,
      },
      book_item: {}, // 书籍详情
      book_id: '', // 书籍id
      sectionArr: [], // 章节列表
      create_item: {
        book_name: '书城', // 书城为首页, 其他单本链接为小说书名
        title: '书城', // 链接名称 可更改
        book_id: '', // 书籍ID
        link_type: '', // 生成链接类型，1=首页，2=单本,
        book_type: '', // 书籍类型 1=小说，2=漫画
      }, // 创建书籍弹框的数据(改小说创建的数据)
      link: '', // 创建成功的链接
      is_books: false, // 是否加载无数据布局
      data_running: false, // 加载数据
      book_channel_dialog: false, // 创建书籍连接
      channel_dialog: false, // 创建书籍连接成功
    };
  },
  created () {
    // console.log(this.$route.params.id)
    this.book_id = this.$route.params.id
    this.setBookDetail()
    this.setSectionList()
  },
  methods: {
    back() {
      this.$router.push({
        path: "/booksList",
      });
    },
    // 分页
    sizeChange(v) {
      this.data.page = v;
      this.setSectionList();
    },
    // 打开创建链接弹框
    createLinkDialog(item) {
      console.log(item)
      this.book_channel_dialog = true
      this.create_item = {
        book_name: item.book_name, // 书城为首页, 其他单本链接为小说书名
        title: item.book_name, // 链接名称 可更改
        book_id: item.book_id, // 书籍ID
        link_type: 2, // 生成链接类型，1=首页，2=单本,
        book_type: item.book_type, // 书籍类型 1=小说，2=漫画
      }
    },
    // 复制
    copy(val) {
      var input = document.createElement("input");
      input.value = val;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.channel_dialog = false
      this.$message({
        message: this.$t('message.copied'),
        type: 'success'
      })
    },
    // 获取小说详情
    async setBookDetail () {
      let res = await bookDetail({
        book_id: this.book_id
      })
      if (res.code === 1) {
        this.book_item = res.data
        this.data_running = false
      } else if (res.code === 5000) {
        this.data_running = true
        this.$message.error(this.$t('message.thisItemIsNoLongerAvailable'));
        setTimeout(() => {
          this.$router.push({
            path: '/booksList'
          })
        }, 1000);
      }
    },
    // 获取小说章节列表
    async setSectionList () {
      this.data_running = true
      let params = {
        book_id: this.book_id,
        page: this.data.page,
        limit: this.data.limit,
        is_need_content: 0
      }
      let res = await sectionList(params)
      if (res.code === 1) {
        this.count = res.count;
        this.sectionArr = res.data
        res.data.length > 0 ? this.is_books = false : this.is_books = true
      }
      JSON.stringify(this.book_item) == '{}'? this.data_running = true : this.data_running = false
      // console.log(res)
    },
    // 创建推广链接
    async createLink() {
      let res = await generateLink(this.create_item)
      if (res.code === 1) {
        this.link = res.data
        this.book_channel_dialog = false
        this.channel_dialog = true
      }
      console.log(res)
    }
  },
  components: {
    contentTitle,
  },
};
</script>
<style lang="scss" scoped>
.book {
  .book_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 200px;
    background-color: #fff;
    width: 100%;
    border-radius: 8px;
    .book_left {
      display: flex;
      background: #ffffff;
      margin-left: 45px;
      > .img_box {
        position: relative;
        img {
          width: 120px;
          height: 160px;
          border-radius: 6px;
          border-color: pink;
        }
        span {
          position: absolute;
          right: 0;
          top: 0;
          width: 60px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          font-size: 12px;
          color: #fff;
          background: #00c3a9;
          border-radius: 0px 5px 0px 8px;
        }
      }
      .synopsis {
        margin-left: 24px;
        .name {
          color: #15161a;
          font-size: 16px;
          font-weight: 500;
          // margin-top: 14px;
        }
        .section {
          margin-top: 16px;
          color: #65676e;
          font-size: 14px;
          font-weight: 400;
        }
        .text {
          margin-top: 16px;
          color: #65676e;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;

          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 3; //显示的行
        }
      }
    }

    .create_book_btn {
      cursor:pointer;
      // width: 150px;
      padding: 0 30px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      background: #3d76ff;
      border-radius: 6px;
      margin-right: 60px;
    }
  }

  .section_list_box {
    width: 100%;
    border-radius: 8px;
    background-color: #fff;
    margin-top: 20px;
    
    .section_title {
      height: 60px;
      span {
        width: 50%;
        padding-left: 130px;
        color: #15161A;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .section_list {
      // height: 376px;
      // overflow-x: hidden;
      // overflow-y: scroll;
      .section_item {
        height: 54px;
        span {
          width: 50%;
          padding-left: 130px;
          color: #32343A;
          font-size: 14px;
        }
        span:nth-child(1) {
          padding-left: 110px;
        }
      }
      .section_item:nth-child(odd) {
        background-color: #f9f9f9;
      }
      .section_item:hover {
        background-color: #F3F6FF;
      }
    }
    .section_list::-webkit-scrollbar {
      display: none;
    }
    .pagination {
      padding-bottom: 20px;
      margin-top: 20px;
    }
  }
  
  .empty {
    height: 500px;
    border-radius: 10px;
    background: #ffffff;
    margin-top: 30px;
    img {
      width: 140px;
    }
    span {
      color: #9497a1;
      font-size: 14px;
    }
  }

  .el-dialog__body {
    .title {
      color: #15161a;
      font-size: 24px;
      text-align: center;
      font-weight: 500;
      margin-top: 20px;
    }
    .channel {
      margin-left: 68px;
      margin-top: 42px;
      .min_title {
        color: #32343a;
        font-size: 16px;
        font-weight: 400;
      }
      .val {
        width: 268px;
        // background-color: red;
        display: inline-block;
        color: #15161a;
        font-size: 16px;
        font-weight: 500;
        margin-left: 14px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
      }
    }
    .name {
      margin-left: 68px;
      margin-top: 25px;
      .min_title {
        color: #32343a;
        font-size: 16px;
        font-weight: 400;
      }
      >::v-deep.el-input {
        width: 300px;
        height: 44px;
        border: 1px solid #c9ccd6;
        border-radius: 6px;
        margin-left: 14px;
        // padding-left: 16px;
        .el-input__inner {
          height: 100%;
          border: 0px solid #3d76ff;
        }
      }
      input::-webkit-input-placeholder {
        color: #9497a1;
        font-size: 14px;
      }
    }

    .btn {
      cursor: pointer;
      margin: 0 auto;
      margin-top: 40px;
      text-align: center;
      width: 200px;
      height: 46px;
      line-height: 46px;
      background: #3d76ff;
      color: #ffffff;
      border-radius: 8px;
    }

    .link {
      width: 462px;
      margin: 0 auto;
      margin-top: 42px;
      .hint {
        color: #65676e;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
      > input {
        width: 100%;
        height: 44px;
        border: 1px solid #c9ccd6;
        border-radius: 6px;
        padding-left: 16px;
        margin-top: 16px;
        color: #9497a1;
      }
      
      >::v-deep.el-input {
        width: 100%;
        height: 44px;
        border: 1px solid #c9ccd6;
        border-radius: 6px;
        margin-top: 18px;
        color: #9497a1;
        .el-input__inner {
          height: 100%;
        }
      }
      input::-webkit-input-placeholder {
        color: #9497a1;
        font-size: 14px;
      }
    }
    .copy_btn {
      margin-top: 46px;
    }
  }
}
</style>
<style lang="scss">
.book {
  .el-dialog {
    border-radius: 10px !important;
    .el-dialog__header {
      padding: 10px 10px 10px;
      .el-icon {
        font-size: 24px;
      }
    }
    .el-dialog__body {
      padding: 0;
      padding-bottom: 40px;
    }
  }
}
</style>